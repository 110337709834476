import axios from 'axios';
import _ from 'lodash';
import queryString from 'query-string';
import { headers } from '../../defaults';
import { API_URL } from '../../../environment';
import {
  UNAUTH_USER,
  ADMIN_FETCH_PROVIDERS,
  ADMIN_UPDATE_CLIENT,
  ADMIN_UPDATE_PROVIDER,
  ADMIN_FETCH_PAGINATED_PROVIDERS_REQUEST,
  ADMIN_FETCH_PAGINATED_PROVIDERS_SUCCESS,
  ADMIN_FETCH_PAGINATED_PROVIDERS_FAILURE,
  ADMIN_FETCH_PROVIDER_REQUEST,
  ADMIN_FETCH_PROVIDER_SUCCESS,
  ADMIN_FETCH_PROVIDER_FAILURE,
  ADMIN_SEARCH_PROVIDERS_SUCCESS,
  ADMIN_SEARCH_PROVIDERS_REQUEST,
  ADMIN_SEARCH_PROVIDERS_FAILURE,
  ADMIN_FETCH_PROVIDER_CLIENTS_REQUEST,
  ADMIN_FETCH_PROVIDER_CLIENTS_SUCCESS,
  ADMIN_FETCH_PROVIDER_CLIENTS_FAILURE,
  ADMIN_FETCH_PAGINATED_CLIENTS_REQUEST,
  ADMIN_FETCH_PAGINATED_CLIENTS_SUCCESS,
  ADMIN_FETCH_PAGINATED_CLIENTS_FAILURE,
  ADMIN_SEARCH_CLIENTS_REQUEST,
  ADMIN_SEARCH_CLIENTS_SUCCESS,
  ADMIN_SEARCH_CLIENTS_FAILURE,
  ADMIN_FETCH_CLIENT_REQUEST,
  ADMIN_FETCH_CLIENT_SUCCESS,
  ADMIN_FETCH_CLIENT_FAILURE,
  ADMIN_FETCH_CLIENT_MATCH_REQUEST,
  ADMIN_FETCH_CLIENT_MATCH_SUCCESS,
  ADMIN_FETCH_CLIENT_MATCH_FAILURE,
  ADMIN_FETCH_CLIENT_INSURANCE_REQUEST,
  ADMIN_FETCH_CLIENT_INSURANCE_SUCCESS,
  ADMIN_FETCH_CLIENT_INSURANCE_FAILURE,
  ADMIN_PUT_CLIENT_INSURANCE_REQUEST,
  ADMIN_PUT_CLIENT_INSURANCE_SUCCESS,
  ADMIN_PUT_CLIENT_INSURANCE_FAILURE,
  ADMIN_DELETE_CLIENT_INSURANCE_REQUEST,
  ADMIN_DELETE_CLIENT_INSURANCE_SUCCESS,
  ADMIN_DELETE_CLIENT_INSURANCE_FAILURE,
  ADMIN_CREATE_CLIENT,
  REMOVE_CLIENT_CREDIT_CARD_REQUEST,
  REMOVE_CLIENT_CREDIT_CARD_SUCCESS,
  REMOVE_CLIENT_CREDIT_CARD_FAILURE,
  ADMIN_UPDATE_GOVERNING_BODIES_SUCCESS,
  ADMIN_DELETE_GOVERNING_BODIES_SUCCESS,
  ADMIN_MATCH_PROVIDER_TO_CLIENT_REQUEST,
  ADMIN_MATCH_PROVIDER_TO_CLIENT_FAILURE,
  ADMIN_MATCH_PROVIDER_TO_CLIENT_SUCCESS,
  ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_REQUEST,
  ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_SUCCESS,
  ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_FAILURE,
  ADMIN_FETCH_CLIENT_INSURANCE_NOT_FOUND,
} from '../../types';

export function fetchProviders(params = '', callback = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/admin/providers${params}`, headers())
      .then(response => {
        dispatch({
          type: ADMIN_FETCH_PROVIDERS,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export const fetchProvider = (id, callback = () => {}) => dispatch => {
  dispatch({
    type: ADMIN_FETCH_PROVIDER_REQUEST,
  });
  axios
    .get(`${API_URL}/admin/providers/${id}`, headers())
    .then(response => {
      dispatch({
        type: ADMIN_FETCH_PROVIDER_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_FETCH_PROVIDER_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
      callback(null, error);
    });
};

export const fetchPaginatedClients = ({
  page,
  providerId = '',
  callback = () => {},
}) => dispatch => {
  dispatch({
    type: ADMIN_FETCH_PAGINATED_CLIENTS_REQUEST,
  });
  axios
    .get(
      `${API_URL}/admin/clients?page=${page}&provider_id=${providerId}`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: ADMIN_FETCH_PAGINATED_CLIENTS_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_FETCH_PAGINATED_CLIENTS_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchPaginatedProviders = (
  page,
  type,
  callback = () => {},
) => dispatch => {
  dispatch({
    type: ADMIN_FETCH_PAGINATED_PROVIDERS_REQUEST,
  });
  axios
    .get(`${API_URL}/admin/providers?page=${page}&type=${type}`, headers())
    .then(response => {
      dispatch({
        type: ADMIN_FETCH_PAGINATED_PROVIDERS_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_FETCH_PAGINATED_PROVIDERS_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchProviderClients = (id, callback = () => {}) => dispatch => {
  dispatch({
    type: ADMIN_FETCH_PROVIDER_CLIENTS_REQUEST,
  });
  axios
    .get(`${API_URL}/admin/providers/${id}/clients`, headers())
    .then(response => {
      dispatch({
        type: ADMIN_FETCH_PROVIDER_CLIENTS_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_FETCH_PROVIDER_CLIENTS_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchClient = (id, callback = () => {}) => dispatch => {
  dispatch({
    type: ADMIN_FETCH_CLIENT_REQUEST,
  });
  axios
    .get(`${API_URL}/admin/clients/${id}`, headers())
    .then(response => {
      dispatch({
        type: ADMIN_FETCH_CLIENT_SUCCESS,
        payload: response,
      });
      console.log(response);
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_FETCH_CLIENT_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchClientInsuranceCoverage = (
  userId,
  callback = () => {},
) => dispatch => {
  dispatch({
    type: ADMIN_FETCH_CLIENT_INSURANCE_REQUEST,
  });
  axios
    .get(
      `${API_URL}/admin/clients/${userId}/user_insurance_coverage`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: ADMIN_FETCH_CLIENT_INSURANCE_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      // Not found is a legitimate possibility, so don't treat it like a normal error
      if (error?.response?.status === 404) {
        dispatch({ type: ADMIN_FETCH_CLIENT_INSURANCE_NOT_FOUND });
        return;
      }

      // Dispatch action for failure in all other cases
      dispatch({ type: ADMIN_FETCH_CLIENT_INSURANCE_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const putClientInsuranceCoverage = ({
  userId,
  insuranceCarrier,
  policyId = null,
  dependantCode = null,
  insuranceIntermediary = null,
  isPrimaryInsurance = null,
  useDirectBilling = null,
  onSuccess = () => {},
  onFailure = () => {},
}) => dispatch => {
  dispatch({
    type: ADMIN_PUT_CLIENT_INSURANCE_REQUEST,
  });

  axios
    .put(
      `${API_URL}/admin/clients/${userId}/user_insurance_coverage`,
      {
        insurance_carrier: insuranceCarrier,
        policy_id: policyId,
        dependant_code: dependantCode,
        insurance_intermediary: insuranceIntermediary,
        is_primary_insurance: isPrimaryInsurance,
        use_direct_billing: useDirectBilling,
      },
      headers(),
    )
    .then(response => {
      dispatch({
        type: ADMIN_PUT_CLIENT_INSURANCE_SUCCESS,
        payload: response,
      });
      onSuccess(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_PUT_CLIENT_INSURANCE_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
      onFailure(error.response.data.message);
    });
};

export const deleteClientInsuranceCoverage = ({
  userId,
  onSuccess = () => {},
  onFailure = () => {},
}) => dispatch => {
  dispatch({
    type: ADMIN_DELETE_CLIENT_INSURANCE_REQUEST,
  });
  axios
    .delete(
      `${API_URL}/admin/clients/${userId}/user_insurance_coverage`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: ADMIN_DELETE_CLIENT_INSURANCE_SUCCESS,
        payload: response,
      });
      onSuccess(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_DELETE_CLIENT_INSURANCE_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
      onFailure(error.response.data.message);
    });
};

export const fetchClientMatch = (id, callback = () => {}) => dispatch => {
  dispatch({
    type: ADMIN_FETCH_CLIENT_MATCH_REQUEST,
  });
  axios
    .get(`${API_URL}/admin/match_answers/${id}`, headers())
    .then(response => {
      dispatch({
        type: ADMIN_FETCH_CLIENT_MATCH_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_FETCH_CLIENT_MATCH_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const updateClient = (id, values, callback = () => {}) => dispatch => {
  axios
    .put(`${API_URL}/admin/clients/${id}`, values, headers())
    .then(response => {
      dispatch({
        type: ADMIN_UPDATE_CLIENT,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const createClient = (values, callback = () => {}) => dispatch => {
  axios
    .post(`${API_URL}/admin/clients/`, values, headers())
    .then(response => {
      dispatch({
        type: ADMIN_CREATE_CLIENT,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const updateProvider = (
  id,
  values,
  callback = () => {},
  errorCallback = () => {},
) => dispatch => {
  axios
    .put(`${API_URL}/admin/providers/${id}`, values, headers())
    .then(response => {
      dispatch({
        type: ADMIN_UPDATE_PROVIDER,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      } else {
        errorCallback(error);
      }
    });
};

export const searchClients = ({
  query,
  showDeleted,
  callback = () => {},
}) => dispatch => {
  dispatch({
    type: ADMIN_SEARCH_CLIENTS_REQUEST,
  });
  axios
    .get(
      `${API_URL}/admin/clients/search?query=${query.query}&show_deleted=${showDeleted}`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: ADMIN_SEARCH_CLIENTS_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_SEARCH_CLIENTS_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const searchProviders = (
  search,
  type,
  callback = () => {},
) => dispatch => {
  dispatch({
    type: ADMIN_SEARCH_PROVIDERS_REQUEST,
  });
  const {
    advancedSearchYn,
    query,
    verified,
    matching,
    deactivated,
    availabilities,
    networks,
    designations,
    governing,
    disorders,
    // eslint-disable-next-line camelcase
    age_groups,
    bioSearchYn,
    modalities,
    languages,
  } = search;

  const parseArr = state => {
    const str = '';
    return _.map(state, x => str.concat(x.value)).join();
  };

  const advancedSearchStr =
    `&advanced_search_yn=${advancedSearchYn}` +
    `&verified=${verified.value}` +
    `&matching=${matching.value}` +
    `&deactivated=${deactivated.value}` +
    `&availabilities=${availabilities.value}` +
    `&networks=${parseArr(networks)}` +
    `&designations=${parseArr(designations)}` +
    `&governing=${parseArr(governing)}` +
    `&disorders=${parseArr(disorders)}` +
    `&age_groups=${parseArr(age_groups)}` +
    `&modalities=${parseArr(modalities)}` +
    `&languages=${parseArr(languages)}`;

  axios
    .get(
      `${API_URL}/admin/providers/search?query=${query}&type=${type}&bio_yn=${bioSearchYn}${advancedSearchStr}`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: ADMIN_SEARCH_PROVIDERS_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: ADMIN_SEARCH_PROVIDERS_FAILURE });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const removeClientCreditCard = (id, callback = () => {}) => dispatch => {
  dispatch({ type: REMOVE_CLIENT_CREDIT_CARD_REQUEST });
  axios
    .delete(`${API_URL}/admin/stripe_customers/${id}`, headers())
    .then(response => {
      dispatch({
        type: REMOVE_CLIENT_CREDIT_CARD_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: REMOVE_CLIENT_CREDIT_CARD_FAILURE });
      }
    });
};

export const createGoverningBodies = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'ADMIN_UPDATE_GOVERNING_BODIES_REQUEST' });
  return axios
    .post(`${API_URL}/admin/governing_bodies`, values, headers())
    .then(response => {
      callback(response);
      return dispatch({
        type: ADMIN_UPDATE_GOVERNING_BODIES_SUCCESS,
        payload: response,
      });
    });
};

export const deleteGoverningBodies = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'ADMIN_DELETE_GOVERNING_BODIES_REQUEST' });
  return axios
    .delete(`${API_URL}/admin/governing_bodies/${values}`, headers())
    .then(response => {
      callback(response);
      return dispatch({
        type: ADMIN_DELETE_GOVERNING_BODIES_SUCCESS,
        payload: response,
      });
    });
};

export const fetchBlockedClients = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'ADMIN_FETCH_BLOCKED_CLIENTS_REQUEST' });
  return axios
    .get(
      `${API_URL}/admin/provider_match_blocks?${queryString.stringify(values)}`,
      headers(),
    )
    .then(response => {
      callback(response, null);
      return dispatch({ type: 'ADMIN_FETCH_BLOCKED_CLIENTS_SUCCESS' });
    })
    .catch(error => {
      callback(null, error);
      return dispatch({ type: 'ADMIN_FETCH_BLOCKED_CLIENTS_FAILURE' });
    });
};

export const addBlockedClient = (values, callback = () => {}) => dispatch => {
  dispatch({ type: 'ADMIN_ADD_BLOCKED_CLIENT_REQUEST' });
  return axios
    .get(
      `${API_URL}/admin/provider_match_blocks/add?${queryString.stringify(
        values,
      )}`,
      headers(),
    )
    .then(response => {
      callback(response, null);
      return dispatch({ type: 'ADMIN_ADD_BLOCKED_CLIENT_SUCCESS' });
    })
    .catch(error => {
      callback(null, error);
      return dispatch({ type: 'ADMIN_ADD_BLOCKED_CLIENT_FAILURE' });
    });
};

export const deleteBlockedClient = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'ADMIN_DELETE_BLOCKED_CLIENT_REQUEST' });
  return axios
    .get(
      `${API_URL}/admin/provider_match_blocks/remove?${queryString.stringify(
        values,
      )}`,
      headers(),
    )
    .then(response => {
      callback(response, null);
      return dispatch({ type: 'ADMIN_DELETE_BLOCKED_CLIENT_SUCCESS' });
    })
    .catch(error => {
      callback(null, error);
      return dispatch({ type: 'ADMIN_DELETE_BLOCKED_CLIENT_FAILURE' });
    });
};

export const fetchBlockedClientInfo = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'ADMIN_FETCH_BLOCKED_CLIENT_INFO_REQUEST' });
  return axios
    .get(
      `${API_URL}/admin/provider_match_blocks/client_info?${queryString.stringify(
        values,
      )}`,
      headers(),
    )
    .then(response => {
      callback(response, null);
      return dispatch({ type: 'ADMIN_FETCH_BLOCKED_CLIENT_INFO_SUCCESS' });
    })
    .catch(error => {
      callback(null, error);
      return dispatch({ type: 'ADMIN_FETCH_BLOCKED_CLIENT_INFO_FAILURE' });
    });
};

export const matchProviderToClient = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: ADMIN_MATCH_PROVIDER_TO_CLIENT_REQUEST });
  axios
    .post(`${API_URL}/admin/matches`, values, headers())
    .then(response => {
      dispatch({
        type: ADMIN_MATCH_PROVIDER_TO_CLIENT_SUCCESS,
        payload: response,
      });
      callback(response, null);
    })
    .catch(error => {
      dispatch({
        type: ADMIN_MATCH_PROVIDER_TO_CLIENT_FAILURE,
        payload: error,
      });
      callback(null, error);
    });
};

export const unmatchProviderFromClient = (
  matchId,
  callback = () => {},
) => dispatch => {
  dispatch({ type: ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_REQUEST });
  axios
    .delete(`${API_URL}/admin/matches/${matchId}`, headers())
    .then(response => {
      dispatch({
        type: ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_SUCCESS,
        payload: response,
      });
      callback(response, null);
    })
    .catch(error => {
      dispatch({
        type: ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_FAILURE,
        payload: error,
      });
      callback(null, error);
    });
};

export const fetchClientMatches = clientId =>
  axios.get(`${API_URL}/admin/matches?client_id=${clientId}`, headers());
